import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { turboHeaders } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["preview", "form", "input", "hiddenField"];
  static values = { url: String };

  connect() {
    // console.log("dz-comment#connect", this.dropzoneTarget);
    if (!this.hasPreviewTarget) {
      return;
    }

    const notyfError = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    const dropzoneConfig = {
      url: this.urlValue,
      method: "post",
      paramName: "file",
      acceptedFiles: "image/*,application/pdf",
      previewsContainer: this.previewTarget,
      dictRemoveFile: "remove",
      maxFiles: 1,
      maxFilesize: 25,
      clickable: true,
      autoProcessQueue: false,
      uploadMultiple: false,
      addRemoveLinks: true,
      headers: turboHeaders(),
    };

    this.dropzone = new Dropzone(this.formTarget, dropzoneConfig);

    this.dropzone.on("init", function () {
      console.log("dropzon Init");
    });

    this.dropzone.on("error", function (file, error) {
      console.log("dz-project", error, file);
      notyfError.error({
        message: `Error Uploading ${file.name} - ${error}`,
        dismissible: true,
      });
    });

    this.dropzone.on("uploadprogress", (file, progress, bytesSent) => {
      console.log("uploadprogress", file);

      const node = document.querySelector(
        `[data-dz-uploadprogress="${file.upload.uuid}"]`
      );
      console.log("node", node);

      node.nodeName === "PROGRESS"
        ? (node.value = progress)
        : (node.style.width = `${progress}%`);
    });

    this.dropzone.on("sending", (file) => {
      window.notyf.success({
        message: `Uploading ${file.name}<br><div class="progress" role="progressbar"><div class="progress-bar progress-bar-striped progress-bar-animated bg-success" data-dz-uploadprogress="${file.upload.uuid}" style="width: 0%"></div></div>`,
        dismissible: true,
      });
    });

    this.dropzone.on("addedfile", (file) => {
      console.log("addedfile", file, this.dropzone.files.length);
    });

    this.dropzone.on("complete", (file) => {
      console.log("complete", file);
      window.notyf.dismissAll();
      this.dropzone.removeFile(file);
    });

    this.dropzone.on("success", (file, html) => {
      console.log("success", file, html);
    });
  }

  submitting(ev) {
    console.log("submitting");
    if (this.dropzone.files.length > 0) {
      ev.preventDefault();
      ev.stopPropagation();

      this.dropzone.processQueue();
    }
  }
}
