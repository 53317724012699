import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="country-code"
export default class extends Controller {
  static targets = ["field", "label"];

  connect() {}

  select(ev) {
    ev.preventDefault();

    const val = ev.params.val;

    this.fieldTarget.innerText = val;
    this.labelTarget.innerText = val;
  }
}
