import { Controller } from "@hotwired/stimulus";
import { titleCase } from "../utils";
import { show, boot } from "@intercom/messenger-js-sdk";
// ...

// Connects to data-controller="container"
export default class extends Controller {
  static targets = ["library", "canvas", "viewLabel", "pinFilter"];

  connect() {
    let view = localStorage.getItem("boardView") || "classic";
    if (view) {
      this.canvasTarget.dataset.view = view;
      document.body.dataset.view = view;
      this.viewLabelTarget.innerText = this.viewName(view);
    }

    window.onkeydown = (e) => {
      if (e.metaKey && e.key == "k") {
        this.libraryTarget.click();
      }
    };
  }

  viewName(view) {
    switch (view) {
      case "classic":
        return `Detailed View`;
        break;
      case "default":
        return `Grid View`;
        break;
      case "gallery":
        return `Presentation View`;
        break;
      case "budget":
        return `Budget View`;
        break;

      default:
        console.log("view");
        return `${titleCase(view)} View`;
        break;
    }
  }

  changeView(ev) {
    ev.preventDefault();

    this.canvasTarget.dataset.view = ev.params.view;
    document.body.dataset.view = ev.params.view;

    localStorage.setItem("boardView", ev.params.view);

    this.viewLabelTarget.innerText = this.viewName(ev.params.view);

    document.body.classList.remove("info-panel-active");
  }

  confirmLeaving(ev) {
    const confirmation = confirm("Are you sure you want to leave this page?");
    if (!confirmation) {
      ev.preventDefault();
    }
  }

  help(ev) {
    ev.preventDefault();
    show();
  }

  tilesLoaded(ev) {
    this.pinFilterTiles();
  }

  pinFilter(ev) {
    ev.currentTarget.classList.toggle("btn-light");
    ev.currentTarget.classList.toggle("btn-primary");
    ev.currentTarget.querySelector("em").classList.toggle("d-none");

    if (ev.currentTarget.dataset.active) {
      delete ev.currentTarget.dataset.active;
    } else {
      ev.currentTarget.dataset.active = true;
    }

    this.pinFilterTiles();
  }

  pinFilterTiles() {
    // shut everything off if there are targets
    const hasActive = this.pinFilterTargets.some((element) =>
      element.hasAttribute("data-active")
    );
    if (hasActive) {
      document
        .querySelectorAll(".tile_container_wrapper")
        .forEach((el) => (el.style.display = "none"));
      document
        .querySelectorAll(".tile-wrapper")
        .forEach((el) => (el.style.display = "none"));
    } else {
      document
        .querySelectorAll(".tile_container_wrapper")
        .forEach((el) => (el.style.display = "block"));
      document
        .querySelectorAll(".tile-wrapper")
        .forEach((el) => (el.style.display = "block"));
    }

    this.pinFilterTargets.forEach((el) => {
      if (el.dataset.active) {
        const className = el.dataset.id;
        document
          .querySelectorAll(`.${className}`)
          .forEach((el) => (el.style.display = "block"));
      }
    });
  }
}
