import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="comments-panel"
export default class extends Controller {
  static targets = ["field"];

  connect() {
    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.add("comments");

    document.body.classList.add("info-panel-active");

    const lastComment = document.querySelector(
      ".comments-panel .comment:last-child"
    );
    lastComment?.scrollIntoView();

    this.isResizing = false;

    const vh = window.innerHeight * 0.01;
    document.getElementById("info_panel").style.setProperty("--vh", `${vh}px`);

    const newHeight = 85;
    this.element.style.setProperty("--panelHeight", `${newHeight}%`);
  }

  submit(ev) {
    if (this.hasFieldTarget) {
      window.setTimeout(() => {
        this.fieldTarget.value = "";
        this.fieldTarget.focus();
      }, 500);
    }
  }

  close(ev) {
    document.body.classList.remove("info-panel-active");
    // document.removeEventListener("keydown", this.bindEscape);

    const infoPanel = document.getElementById("info_panel");
    infoPanel?.classList.remove("comments");
  }

  closeCommentPanel(ev) {
    ev.preventDefault();
    ev.stopPropagation();

    this.element.classList.remove("comments-active");
    document.getElementById("commentsSection").style.height = `auto`;
    document
      .querySelector(".comments-active")
      .classList.remove("comments-active");
  }

  resize(ev) {
    ev.currentTarget.classList.remove("rounded-pill");
    ev.currentTarget.classList.add("rounded");
    ev.currentTarget.style.height = "auto";
    ev.currentTarget.style.height = ev.currentTarget.scrollHeight + "px";
  }

  startResizing(event) {
    this.element.classList.add("dragging");
    this.isResizing = true;

    // Check if it's a touch event
    this.startY =
      event.type === "touchstart" ? event.touches[0].clientY : event.clientY;
    this.startHeight = (this.element.offsetHeight / window.innerHeight) * 100; // Convert initial height to %

    // Add both mouse and touch listeners
    document.addEventListener("mousemove", this.resizePanel);
    document.addEventListener("mouseup", this.stopResizing);
    document.addEventListener("touchmove", this.resizePanel);
    document.addEventListener("touchend", this.stopResizing);
  }

  resizePanel = (event) => {
    if (!this.isResizing) return;

    // Use touch coordinates if it's a touch event
    const currentY =
      event.type === "touchmove" ? event.touches[0].clientY : event.clientY;
    const dy = currentY - this.startY;
    let newHeight = this.startHeight - (dy / window.innerHeight) * 100;

    // Constrain the new height
    newHeight = Math.max(20, Math.min(85, newHeight));
    this.element.style.setProperty("--panelHeight", `${newHeight}%`);
  };

  stopResizing = () => {
    this.isResizing = false;

    this.element.classList.remove("dragging");

    // Remove both mouse and touch listeners
    document.removeEventListener("mousemove", this.resizePanel);
    document.removeEventListener("mouseup", this.stopResizing);
    document.removeEventListener("touchmove", this.resizePanel);
    document.removeEventListener("touchend", this.stopResizing);
  };
}
