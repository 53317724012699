import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="subscriptions"
export default class extends Controller {
  static values = { url: String, downgradeUrl: String };
  static targets = ["input"];

  connect() {}

  select(ev) {
    location.href = this.urlValue;
  }

  proceed(ev) {
    if (this.inputTarget.value == "proceed") {
      location.href = this.downgradeUrlValue;
    }
  }
}
