import { Controller } from "@hotwired/stimulus";
import Dropzone from "dropzone";
import { Notyf } from "notyf";
import { csrfToken, headers } from "../utils";

Dropzone.autoDiscover = false;

export default class extends Controller {
  static targets = ["addblock"];
  static values = {
    url: String,
    objectUrl: String,
  };

  connect() {
    const notyfError = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });

    const dropzoneConfig = {
      url: this.urlValue,
      method: "post",
      paramName: "item[media]",
      clickable: true,
      maxFilesize: 25,
      maxFiles: 6,
      headers: {
        "X-CSRF-Token": csrfToken(),
      },
    };

    this.dropzone = new Dropzone(this.element, dropzoneConfig);

    this.dropzone.on("addedfile", (file) => {
      console.log("addedfile", file);
      const container = document.querySelector(".tile_container_wrapper");
      const tiles = container.querySelector(".tiles");

      file.previewElement.id = `upload_${file.upload.uuid}`; // use this for replacing later
      tiles.prepend(file.previewElement);
    });

    this.dropzone.on("sending", (file) => {});

    this.dropzone.on("error", function (file, errorMessages) {
      notyfError.error({
        message: errorMessages.errors
          ? `Error Uploading ${file.name}: ${errorMessages.errors}`
          : `Error Uploading ${file.name}`,
        dismissible: true,
      });
    });

    this.dropzone.on("complete", (file) => {
      // this.dropzone.removeFile(file);
    });

    this.dropzone.on("success", (file, data) => {
      console.log("data", data, file);
      this.createIdea(file, data);
    });
  }

  click(ev) {
    ev.preventDefault();
    this.dropzone.hiddenFileInput.click();
  }

  createIdea(file, item) {
    // fix the item id
    document.getElementById(
      `upload_${file.upload.uuid}`
    ).id = `dropped_item_${item.id}`;

    fetch(this.objectUrlValue, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({ idea: { item_id: item.id, position: 1 } }),
    })
      .then((resp) => resp.text())
      .then((html) => Turbo.renderStreamMessage(html))
      .catch((error) => {
        console.error("error", error);
      });
  }
}
