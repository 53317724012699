import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="public-tile"
export default class extends Controller {
  connect() {
    this.handlePermissions();
  }

  handlePermissions() {
    const canvas = document.getElementById("canvas");

    const keys = ["n", "d", "pq", "uc", "lbl", "src"];
    const permitted = this.extractVPKeys(canvas);

    keys.forEach((key) => {
      if (!permitted.includes(key)) {
        this.remove(key);
      }
    });
  }

  extractVPKeys(canvas) {
    const result = [];

    for (let key in canvas.dataset) {
      if (key.startsWith("vp")) {
        const suffix = key.slice(2).toLowerCase();
        result.push(suffix);
      }
    }

    return result;
  }

  remove(permission) {
    this.element
      .querySelectorAll(`.d-vp-${permission}`)
      .forEach((el) => el.remove());
  }
}
