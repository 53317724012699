import { Controller } from "@hotwired/stimulus";
import { csrfToken, headers } from "../utils";

export default class extends Controller {
  static values = { url: String };

  connect() {
    this.clientId =
      "351427123659-r9jf6hl18euup53e36bmpv0vfa95agmp.apps.googleusercontent.com";
    this.appId = "wecora-website";
    this.developerKey = "AIzaSyCzKUoazGlE__7HKgp5uPbUnDJMaXMKSkk";

    this.gisTokenClient = null;
    this.oauthToken = null;

    // Load the Picker API
    gapi.load("picker", () => {});

    // Initialize Google Identity Services client for OAuth
    this.initializeGisClient();
  }

  initializeGisClient() {
    // Initialize the token client for authorization
    this.gisTokenClient = google.accounts.oauth2.initTokenClient({
      client_id: this.clientId,
      scope: "https://www.googleapis.com/auth/drive.readonly",
      callback: (response) => this.handleAuthResponse(response),
    });
  }

  handleAuthResponse(response) {
    if (response.error) {
      console.error("Error during token acquisition:", response.error);
      return;
    }
    this.oauthToken = response.access_token;
    this.createPicker(); // Trigger Picker once OAuth is successful
  }

  show(ev) {
    ev.preventDefault();

    if (this.oauthToken) {
      this.createPicker();
    } else {
      this.gisTokenClient.requestAccessToken();
    }
  }

  createPicker() {
    if (!this.oauthToken) {
      console.error("OAuth token not available.");
      return;
    }

    const picker = new google.picker.PickerBuilder()
      .setAppId(this.appId)
      .setOAuthToken(this.oauthToken)
      .addView(google.picker.ViewId.DOCS_IMAGES)
      .addView(google.picker.ViewId.PDFS)
      .setDeveloperKey(this.developerKey)
      .setCallback(this.pickerCallback.bind(this))
      .build();

    picker.setVisible(true);
  }

  pickerCallback(data) {
    if (data.action === google.picker.Action.PICKED) {
      const file = data.docs[0];
      console.log("Selected file:", file);
      this.downloadFile(file);
    }
  }

  downloadFile(fileObj) {
    const url = `https://www.googleapis.com/drive/v3/files/${fileObj.id}?alt=media`;

    window.notyf.success({
      message: "Adding file from Google Drive...",
      duration: 10000,
    });

    fetch(url, {
      method: "GET",
      headers: {
        Authorization: `Bearer ${this.oauthToken}`, // Pass the OAuth token in the headers
      },
    })
      .then((response) => {
        if (!response.ok) {
          throw new Error(
            `Failed to download file. Status: ${response.status}, Status Text: ${response.statusText}`
          );
        }
        return response.blob(); // Get the file as a blob
      })
      .then((blob) => {
        // Convert the blob to a file object (Paperclip expects a file)
        const file = new File([blob], fileObj.name, {
          type: blob.type,
        });

        // Prepare FormData for the file upload
        const formData = new FormData();
        formData.append("item[name]", fileObj.name);
        formData.append("item[description]", fileObj.description);
        formData.append("item[bucket]", "google-drive");
        formData.append("item[media]", file);

        // Send the form data to your Rails backend
        fetch(this.urlValue + ".json", {
          method: "POST",
          headers: {
            "X-CSRF-Token": csrfToken(),
          },
          body: formData,
        })
          .then((response) => {
            if (!response.ok) {
              throw new Error("File upload failed");
            }
            return response.json();
          })
          .then((json) => {
            window.notyf.dismissAll();

            const container = document.querySelector(".tile_container_wrapper");
            const tiles = container.querySelector(".tiles");
            const url = container.dataset.containerIdeasUrlValue;

            const div = document.createElement("div");
            div.style.opacity = 0.15;
            div.className = "sortable-ghost";
            div.id = `dropped_item_${json.id}`; // use this for replacing later
            tiles.prepend(div);

            this.createIdea(url, json.id, 0);
          })
          .catch((error) => {
            console.error("Error uploading the file:", error);
          });
      })
      .catch((error) => {
        console.error("Error downloading the file:", error);
      });
  }

  createIdea(url, itemId, position) {
    Turbo.fetch(url, {
      method: "POST",
      headers: headers(),
      body: JSON.stringify({
        idea: { item_id: itemId, position: position },
      }),
    })
      .then((resp) => resp.text())
      .then((html) => {
        // evt.item.remove();
        Turbo.renderStreamMessage(html);
      })
      .catch((error) => {
        console.error("error", error);
      });
  }
}
