import { Controller } from "@hotwired/stimulus";
import { Notyf } from "notyf";

export default class extends Controller {
  static values = { type: String, msg: String };

  connect() {
    console.log("flash", this.typeValue);

    const notyf = new Notyf({
      duration: 4000,
      position: { x: "right", y: "top" },
    });
    if (this.typeValue == "alert")
      notyf.success({
        message: this.msgValue,
        dismissible: true,
      });

    if (this.typeValue == "warn")
      notyf.success({
        message: this.msgValue,
        dismissible: true,
      });

    if (this.typeValue == "success")
      notyf.success({
        message: this.msgValue,
        dismissible: true,
      });

    if (this.typeValue == "notice")
      notyf.success({
        message: this.msgValue,
        dismissible: true,
      });

    if (this.typeValue == "error")
      notyf.error({
        message: this.msgValue,
        dismissible: true,
      });
  }
}
