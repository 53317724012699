import { Controller } from "@hotwired/stimulus";
import { headers } from "../utils";

// Connects to data-controller="item-field"
export default class extends Controller {
  static targets = ["inputWrapper", "btn", "deleteBtn"];
  static values = { url: String };

  connect() {
    console.log("item_field#connect", this.urlValue);
  }

  change(ev) {
    const option = ev.currentTarget.options[ev.currentTarget.selectedIndex];
    const type = option.dataset.type;

    const input = document.createElement("input");
    input.name = "item_field[value]";
    input.classList.add("form-control");
    input.required = true;
    input.dataset.fieldTarget = "field";

    switch (type) {
      case "text":
        input.type = "text";
        break;
      case "number":
        input.type = "number";
        input.step = "any";
        break;
      case "date":
        input.type = "date";
        break;

      default:
        break;
    }

    if (!type) {
      this.inputWrapperTarget.replaceChildren();
      this.btnTargets.forEach((el) => el.classList.add("d-none"));
    } else if (type === "boolean") {
      this.buildBoolean(this.inputWrapperTarget);
      this.btnTargets.forEach((el) => el.classList.remove("d-none"));
    } else {
      this.inputWrapperTarget.replaceChildren(input);
      this.btnTargets.forEach((el) => el.classList.remove("d-none"));
      input.focus();
    }
  }

  getCurrentDate() {
    const date = new Date();
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are 0-based
    const day = String(date.getDate()).padStart(2, "0");
    return `${year}-${month}-${day}`;
  }

  buildBoolean(wrapper) {
    wrapper.replaceChildren();

    // Create the first radio button container
    const div1 = document.createElement("div");
    div1.className = "form-check form-check-inline";

    const input1 = document.createElement("input");
    input1.className = "form-check-input";
    input1.type = "radio";
    input1.name = "item_field[value]";
    input1.id = "inlineRadio1";
    input1.checked = true;
    input1.value = "true";
    input1.dataset.fieldTarget = "field";

    const label1 = document.createElement("label");
    label1.className = "form-check-label";
    label1.htmlFor = "inlineRadio1";
    label1.textContent = "Yes";

    div1.appendChild(input1);
    div1.appendChild(label1);

    // Create the second radio button container
    const div2 = document.createElement("div");
    div2.className = "form-check form-check-inline";

    const input2 = document.createElement("input");
    input2.className = "item-field[value]";
    input2.type = "radio";
    input2.name = "item_field[value]";
    input2.id = "inlineRadio2";
    input2.value = "false";

    const label2 = document.createElement("label");
    label2.className = "form-check-label ms-1";
    label2.htmlFor = "inlineRadio2";
    label2.textContent = "No";

    div2.appendChild(input2);
    div2.appendChild(label2);

    // Append both containers to the desired parent element
    wrapper.appendChild(div1);
    wrapper.appendChild(div2);
  }

  reset(ev) {
    this.deleteBtnTarget.style.display = "flex";
  }

  delete(ev) {
    ev.preventDefault();

    fetch(this.urlValue, {
      method: "DELETE",
      headers: headers(),
    })
      .then((resp) => resp.text())
      .then((html) => {
        Turbo.renderStreamMessage(html);
      })
      .catch((err) => {
        window.notyf.error(err);
      });
  }
}
