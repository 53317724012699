import { Controller } from "@hotwired/stimulus";

// Connects to data-controller="zoom"
export default class extends Controller {
  connect() {}

  zoom(ev) {
    const modalDiv = document.createElement("div");
    modalDiv.className = "modal fade";
    modalDiv.id = "imageModal";
    modalDiv.tabIndex = -1;
    modalDiv.setAttribute("aria-labelledby", "imageModalLabel");
    modalDiv.setAttribute("aria-hidden", "true");

    const name = ev.params.name || "";

    modalDiv.innerHTML = `
          <div class="modal-dialog modal-lg">
              <div class="modal-content">
                  <div class="modal-header">
                      <h5 class="modal-title">${name}</h5>
                      <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
                  </div>
                  <div class="modal-body text-center">
                      <img src="${ev.params.image}" class="img-fluid rounded" alt="Zoomed image">
                  </div>
              </div>
          </div>
      `;

    document.body.appendChild(modalDiv);

    const modal = new bootstrap.Modal(modalDiv);
    modal.show();

    modalDiv.addEventListener("hidden.bs.modal", function () {
      document.body.removeChild(modalDiv);
    });
  }
}
