import { Controller } from "@hotwired/stimulus";
import VanillaContextMenu from "vanilla-context-menu";
import { destroy } from "@rails/request.js";
// Connects to data-controller="comment"
export default class extends Controller {
  static values = { url: String };

  connect() {
    console.log("comment#connect");

    this.contextMenu();
  }

  contextMenu() {
    const menuItems = [
      {
        label: "Delete",
        callback: () => {
          destroy(this.urlValue, {
            responseKind: "turbo-stream",
          });
        },
        iconClass: "fa fa-trash", // this only works if you have FontAwesome icons
      },
    ];

    new VanillaContextMenu({
      scope: this.element,
      customThemeClass: "cm-bootstrap",
      transitionDuration: 0,
      menuItems: menuItems,
    });
  }
}
